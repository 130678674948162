import React from 'react';
import { Animated } from 'react-animated-css';
import Bar from './Bar';
import './Content.css';

const ContentDesktop = ({ content, openBar, setOpenBar }) => {
  return (
    <Animated animationIn='slideInRight' animationInDuration={1000}>
      <div
        className='Content'
        onWheel={e => {
          if (e.deltaY > 0 && openBar < 3) setOpenBar(openBar + 1);
          else if (e.deltaY < 0 && openBar > 0) setOpenBar(openBar - 1);
        }}
      >
        {content.map(({ title, color1, color2, Content }, index) => (
          <Bar
            key={title}
            title={title}
            color1={color1}
            color2={color2}
            open={openBar === index}
            onClick={() => setOpenBar(index)}
          >
            {Content && <Content />}
          </Bar>
        ))}
      </div>
    </Animated>
  );
};

export default ContentDesktop;

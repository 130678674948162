import React, { useState, useEffect } from 'react';
import { Animated } from 'react-animated-css';
import Tab from './Tab';
import './Content.css';

const ContentMobile = ({ content, openBar, setOpenBar }) => {
  const [openSection, setOpenSection] = useState(null);
  const [transition, setTransition] = useState(true);

  useEffect(() => {
    setOpenSection(content[openBar]);
    setTransition(true);
  }, [content, openBar]);

  useEffect(() => {
    if (transition === true) setTransition(false);
  }, [transition]);

  return (
    <Animated animationIn='slideInUp' animationInDuration={1000}>
      <div>
        <div className='TabsContainer'>
          <div className='Tabs'>
            {content.map(({ title, color1 }, index) => (
              <Tab
                key={title}
                title={title}
                color1={color1}
                onClick={() => setOpenBar(index)}
              />
            ))}
          </div>
        </div>
        <div className='Content-mobile'>
          {!transition && openSection && (
            <div
              className='Section-mobile'
              style={{ backgroundColor: openSection.color2 }}
            >
              <Animated
                animationIn='slideInUp'
                animationInDuration={500}
                style={{ position: 'relative' }}
              >
                {openSection.Content && <openSection.Content />}
              </Animated>
            </div>
          )}
        </div>
      </div>
    </Animated>
  );
};

export default ContentMobile;

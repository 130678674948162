import React from 'react';

const Work = () => {
  return (
    <ul>
      <li>
        <a href='https://tomemosenserio2018.web.app/'>Tomemos en Serio</a>
        {`, live drinking game website for political debates. (Angular 4, Firebase)`}
      </li>
      <li>
        <a href='https://goo.gl/cchhRL'>ULAN Cocina China</a>
        {`, a landing page with menu for a restaurant. (Bootstrap)`}
      </li>
      <li>
        <a href='https://goo.gl/JgJ4zm'>ColumnistosCR</a>
        {`, a bot that tweets daily the percentage of women writing op-eds in Costa Rica’s national newspapers. (Python)`}
      </li>
      <li>
        <a href='https://goo.gl/gS22x5'>PrestaTEC</a>
        {`, a platform to help students or members of an organization exchange things and offer services. (Angular 2, Firebase)`}
      </li>
      <li>
        <a href='https://goo.gl/qTC2a9'>ChepeRecicla</a>
        {`, an interactive map to keep track of recycling efforts at governmental institutions. (Bootstrap, Google Maps API)`}
      </li>
      <br />
    </ul>
  );
};

export default Work;

import React from 'react';
import './Tab.css';

const Tab = ({ color1, title, onClick }) => {
  return (
    <div
      className='Tab'
      onClick={onClick}
      style={{
        backgroundColor: color1
      }}
    >
      <span>{title}</span>
    </div>
  );
};

export default Tab;

import React from 'react';

const Contact = () => {
  return (
    <ul>
      <li>
        <a href='https://github.com/gabygarro'>GitHub</a>
      </li>
      <li>
        <a href='https://linkedin.com/in/gabygarro'>LinkedIn</a>
      </li>
    </ul>
  );
};

export default Contact;

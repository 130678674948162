import React from 'react';

const About = () => {
  return (
    <div>
      <p>{`Hi, my name is Gaby Garro.`}</p>
      <p>{`I'm a Front End Developer and Web Designer based in Alajuela, Costa Rica.`}</p>
      <p>
        {`I have `}
        <span className='underline'>3 years of experience</span>
        {` coding for personal projects and doing professional work for companies.`}
      </p>
      <p>
        {`I enjoy making projects that combine `}
        <span className='underline'>activism, entertainment or innovation</span>
        {` with the most current technology.`}
      </p>
      <p>
        {`I have experience carrying out migrations and refactoring applications to eliminate technical debt.`}
      </p>
      <p>
        {`Also, I'm used to working with international teams at multiple locations using Agile practices.`}
      </p>
      <br />
    </div>
  );
};

export default About;

import React from 'react';

const Skills = () => {
  return (
    <ul>
      <li>Frontend</li>
      <ul>
        <li>React</li>
        <li>Redux</li>
        <li>Material UI</li>
        <li>CSS Flexbox</li>
        <li>Angular 2+</li>
      </ul>
      <li>Mobile</li>
      <ul>
        <li>Ionic (Angular)</li>
        <li>React Native</li>
        <li>Android Studio</li>
      </ul>
      <li>Backend</li>
      <ul>
        <li>Node.js</li>
        <li>Docker</li>
        <li>Firebase</li>
        <li>GraphQL</li>
        <li>Python</li>
        <li>Blockchain (Ethereum)</li>
      </ul>
    </ul>
  );
};

export default Skills;

import React, { useState, useEffect } from 'react';
import ContentMobile from './ContentMobile';
import ContentDesktop from './ContentDesktop';
import About from './sections/About';
import Skills from './sections/Skills';
import Work from './sections/Work';
import Contact from './sections/Contact';

const Main = () => {
  const [openBar, setOpenBar] = useState(-1);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpenBar(0);
    }, 1500);
  }, []);

  const content = [
    { title: 'About', color1: '#37474F', color2: '#263238', Content: About },
    {
      title: 'Skills',
      color1: '#00BCD4',
      color2: '#00ACC1',
      Content: Skills
    },
    { title: 'Work', color1: '#1DE9B6', color2: '#09d3ac', Content: Work },
    { title: 'Contact', color1: '#AED581', color2: '#9CCC65', Content: Contact }
  ];
  const props = { content, openBar, setOpenBar };

  return width <= 800 ? (
    <ContentMobile {...props} />
  ) : (
    <ContentDesktop {...props} />
  );
};

export default Main;

import React from 'react';
import './Bar.css';
import logo from '../g_logo.svg';

const Bar = ({ color1, color2, title, open, showLogo, onClick, children }) => {
  const contentStyle = open ? 'Content-open' : 'Content-closed';

  return (
    <div
      className='MainBar'
      onClick={onClick}
      style={{
        backgroundColor: color1
      }}
    >
      <div className='Bar'>
        <div className='Logo'>
          {showLogo && <img src={logo} className='Logo' alt='logo' />}
        </div>
        <div className='Bar-title'>
          <h2>
            <button className='Bar-button'>{title}</button>
          </h2>
        </div>
      </div>
      <div className={contentStyle} style={{ backgroundColor: color2 }}>
        {children}
      </div>
    </div>
  );
};

export default Bar;
